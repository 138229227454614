import clsx from 'clsx';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { FC, useCallback, useState } from 'react';
import Button from 'src/components/Button';
import api from 'src/lib/api';
import { UploadedFile } from 'src/types/file';
import { Breakpoint } from 'src/types/forms';
import { DATE_FORMAT } from 'src/utils/formatDate';
import * as Yup from 'yup';

import {
    Box, Card, CardContent, CardHeader, Divider, FormControlLabel, FormHelperText, Grid,
    GridSpacing, makeStyles, Switch, TextField, Typography
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { KeyboardDatePicker } from '@material-ui/pickers';

import FilesDropzone from './FilesDropzone';

interface FormProps {
  className?: string;
  messages: {
    success: string;
    fail: string;
  };
  action: (values: object) => Promise<void | string | object>;
  actionDisabled?: boolean;
  fields: {
    name: string;
    label: string;
    initialValue: string | number | boolean | Date | UploadedFile[];
    validation?: object;
    props?: any;
    pos?: {
      lg?: Breakpoint;
      md?: Breakpoint;
      sm?: Breakpoint;
      xs?: Breakpoint;
    };
    type?: string;
    options?: any[];
    useKey?: boolean;
    key?: string;
    text?: string;
    value?: string;
    emptyOption?: boolean;
    disabled?: boolean;
    multipleUpload?: boolean;
  }[];
  submitLabel: string;
  cardTitle: string;
  cardHeaderAction?: any;
  error?: boolean;
  withAttachments?: boolean;
  spacing?: GridSpacing;
  footer?: any;
  freezed?: boolean;
  isNew?: boolean;
}

type InitialValuesType = {
  submit: string;
  [key: string]: string | number | boolean | Date | UploadedFile[];
};

const useStyles = makeStyles(() => ({
  root: {},
  checkbox: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  }
}));

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const FILE_API = api.files;

const Form: FC<FormProps> = ({
  className,
  messages,
  action,
  actionDisabled,
  fields,
  submitLabel,
  cardTitle,
  error,
  withAttachments,
  cardHeaderAction,
  spacing,
  footer,
  freezed,
  isNew,
  ...rest
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [attachments, setAttachments] = useState<UploadedFile[]>([]);

  const initialValues: InitialValuesType = { submit: '' };
  const validations = {};
  for (const field of fields) {
    if (field.props?.type === 'number' && !field.initialValue) {
      field.initialValue = 0;
    }
    initialValues[field.name] = field?.initialValue ?? '';

    validations[field.name] = field?.validation ?? Yup.string();
    if (!field.pos) field.pos = {};
  }
  const validationSchema = Yup.object().shape(validations);

  const onDrop = useCallback((newAttachments) => {
    setAttachments((prevAttachments) => [...prevAttachments].concat(newAttachments));
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
        try {
          delete values.submit;
          if (withAttachments) {
            delete values.attachment;
            const formData = new FormData();
            if (attachments?.length) {
              attachments.forEach((item) => formData.append('attachment', item));
            }
            Object.keys(values).forEach((key) => formData.append(key, values[key] + ''));
            await action(formData);
          } else {
            await action(values);
          }

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          setAttachments([]);
          enqueueSnackbar(messages.success, { variant: 'success' });
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          if (err.field) {
            setErrors({ [err.field]: err.message });
          }
          setSubmitting(false);
          if (err.field === 'submit') {
            enqueueSnackbar(`${messages.fail}: ${err.message}`, {
              variant: 'error'
            });
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values, status }) => (
        <form onSubmit={handleSubmit}>
          <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader title={cardTitle} action={cardHeaderAction} />
            <Divider />
            <CardContent>
              <Grid container spacing={spacing ? spacing : 4}>
                {error && (
                  <Typography variant="h4" color="textPrimary" style={{ padding: 30 }}>
                    Se incarca...
                  </Typography>
                )}
                {!error &&
                  fields.map((field) => {
                    return (
                      <Grid
                        item
                        lg={field.pos.lg ?? 6}
                        md={field.pos.md ?? 6}
                        sm={field.pos.sm ?? 12}
                        xs={field.pos.xs ?? 12}
                        key={field.label}
                      >
                        {!field.type && (
                          <TextField
                            error={Boolean(touched[field.name] && errors[field.name])}
                            fullWidth
                            helperText={touched[field.name] && errors[field.name]}
                            label={field.label}
                            name={field.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values[field.name]}
                            variant="outlined"
                            disabled={!!field.disabled || !!freezed}
                            InputLabelProps={{ shrink: !!values[field.name] }}
                            {...field.props}
                          />
                        )}
                        {field.type === 'checkbox' && (
                          <div className={classes.checkbox}>
                            <FormControlLabel
                              control={
                                // <Checkbox checked={!!values[field.name]} onChange={handleChange} name={field.name} />
                                <Switch checked={!!values[field.name]} onChange={handleChange} name={field.name} />
                              }
                              disabled={!!field.disabled || !!freezed}
                              label={field.label}
                            />
                          </div>
                        )}
                        {field.type === 'option' && (
                          <TextField
                            fullWidth
                            label={field.label}
                            name={field.name}
                            onChange={handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={values[field.name]}
                            variant="outlined"
                            disabled={!!field.disabled || !!freezed}
                            InputLabelProps={{ shrink: !!values[field.name] }}
                            error={Boolean(touched[field.name] && errors[field.name])}
                            helperText={touched[field.name] && errors[field.name]}
                          >
                            <option key="" value="" disabled={!field.emptyOption}></option>
                            {field.options.map(({ key, value }) => (
                              <option key={key} value={field.useKey ? key : value}>
                                {value}
                              </option>
                            ))}
                          </TextField>
                        )}
                        {field.type === 'date' && (
                          <KeyboardDatePicker
                            fullWidth
                            label={field.label}
                            name={field.name}
                            inputVariant="outlined"
                            value={
                              values[field.name] && values[field.name] !== '' ? new Date(values[field.name] + '') : null
                            }
                            format={DATE_FORMAT}
                            clearable
                            error={Boolean(touched[field.name] && errors[field.name])}
                            helperText={touched[field.name] && errors[field.name]}
                            onChange={(date) => {
                              const formatDate = date ? date.toISOString() : null;
                              setFieldValue(field.name, formatDate);
                            }}
                          />
                        )}
                        {field.type === 'upload' && (
                          <FilesDropzone
                            multiple={field.multipleUpload}
                            onDrop={onDrop}
                            onDelete={async (index: number, fileId: string) => {
                              const arrValues = values[field.name] as UploadedFile[];
                              if (fileId) {
                                try {
                                  await FILE_API.delete(fileId);
                                  const updatedFiles = [...arrValues];
                                  updatedFiles.splice(index, 1);

                                  setFieldValue(field.name, updatedFiles);
                                } catch (error) {}
                              } else {
                                const attachmentIndex = index - arrValues?.length;
                                if (attachmentIndex > -1) {
                                  const updatedAttachments = [...attachments];
                                  updatedAttachments.splice(attachmentIndex, 1);
                                  setAttachments(updatedAttachments);
                                }
                              }
                            }}
                            values={values[field.name] as UploadedFile[]}
                            attachments={attachments}
                          />
                        )}
                      </Grid>
                    );
                  })}
                {footer && (
                  <Grid item key="footer">
                    {footer}
                  </Grid>
                )}
              </Grid>
            </CardContent>
            {!error && <Divider />}
            {!error && (
              <Box p={2} display="flex" justifyContent="flex-end">
                {errors.submit && (
                  <FormHelperText style={{ flexGrow: 1 }} error>
                    {errors.submit}
                  </FormHelperText>
                )}
                <Button
                  loading={isSubmitting}
                  disabled={actionDisabled || !!freezed}
                  type="submit"
                  label={submitLabel}
                  success={(status?.success && isEmpty(touched)) ?? false}
                  icon={isNew ? <AddCircleOutlineIcon /> : <SaveOutlinedIcon />}
                />
              </Box>
            )}
          </Card>
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  // @ts-ignore
  className: PropTypes.string,
  action: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired
};

export default Form;

import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import HomeGuard from 'src/components/HomeGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import LoginGuard from 'src/components/LoginGuard';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import { Routes } from 'src/types/routes';

import NoAccessGuard from './components/NoAccessGuard';

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} navigation={route.navigation} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/pages/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/download/:itemId',
    component: lazy(() => import('src/pages/download/DownloadView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/connect',
    component: lazy(() => import('src/pages/auth/ConnectView'))
  },
  {
    exact: true,
    guard: LoginGuard,
    path: '/login',
    component: lazy(() => import('src/pages/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/pages/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/recover',
    component: lazy(() => import('src/pages/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    path: '/reset-password/:token',
    component: lazy(() => import('src/pages/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: NoAccessGuard,
    path: '/no-access',
    component: lazy(() => import('src/pages/auth/NoAccessView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/pages/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/pub/articles',
        navigation: {
          current: 'Lista evenimente'
        },
        component: lazy(() => import('src/pages/articles/ArticlePubView'))
      },
      {
        exact: true,
        path: '/app/pub/budget',
        navigation: {
          current: 'Contributii'
        },
        component: lazy(() => import('src/pages/budget'))
      },
      {
        exact: true,
        path: '/app/pub/rituals',
        navigation: {
          current: 'Ținute Rituale'
        },
        component: lazy(() => import('src/pages/articles/ArticleRitualView'))
      },
      {
        path: '/app/pub/articles/:itemId',
        navigation: {
          current: 'Vizualizare eveniment'
        },
        component: lazy(() => import('src/pages/articles/ArticleView'))
      },
      {
        exact: true,
        path: '/app/pub/present',
        navigation: {
          current: 'Lista prezenta'
        },
        component: lazy(() => import('src/pages/articles/ArticlePresentList'))
      },
      {
        exact: true,
        path: '/app/pub/archive',
        navigation: {
          current: 'Arhiva'
        },
        component: lazy(() => import('src/pages/archive'))
      },
      {
        exact: true,
        path: '/app/management/articles',
        navigation: {
          current: 'Lista evenimente'
        },
        component: lazy(() => import('src/pages/articles/ArticleListView'))
      },
      {
        exact: true,
        path: '/app/management/articles/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/app/management/articles/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/app/management/articles/:itemId/:tab',
        navigation: {
          current: 'Editare eveniment',
          links: [{ label: 'Lista evenimente', url: '/app/management/articles' }]
        },
        component: lazy(() => import('src/pages/articles/ArticleEditView'))
      },
      {
        exact: true,
        path: '/app/management/present',
        navigation: {
          current: 'Prezenta'
        },
        component: lazy(() => import('src/pages/articles/ArticleUsersPresentList'))
      },
      {
        exact: true,
        path: '/app/management/users',
        navigation: {
          current: 'Lista utilizatori'
        },
        component: lazy(() => import('src/pages/user/UserListView'))
      },
      {
        exact: true,
        path: '/app/management/users/:itemId',
        navigation: {
          current: 'Editare utilizator',
          links: [{ label: 'Lista utilizatori', url: '/app/management/users' }]
        },
        component: lazy(() => import('src/pages/user/UserEditView'))
      },
      {
        exact: true,
        path: '/app/management/emails',
        navigation: {
          current: 'Lista emails'
        },
        component: lazy(() => import('src/pages/emails/EmailListView'))
      },
      {
        exact: true,
        path: '/app/management/activity-log',
        navigation: {
          current: 'Activitate'
        },
        component: lazy(() => import('src/pages/activity'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/users" />
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/pub/budget" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeGuard
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;

import { Attend } from './attend';

export interface User {
  id?: string;
  avatar?: string;
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  hasAccess?: boolean;
  startDate?: Date;
  isActive?: boolean;
  budgetLink?: string;
  orderNo?: number;
  role: USER_ROLES;
  level: USER_LEVEL;
  attends: Attend[];
  attendStatusLoading?: boolean; // only on client

  [key: string]: any;
}

export enum USER_ROLES {
  Admin = 'Admin',
  Member = 'Membru'
}

export enum USER_LEVEL {
  Ucenic = 'Ucenic',
  Calfa = 'Calfa',
  Maestru = 'Maestru'
}
